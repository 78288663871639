<template>
  <div>
    <div>
      <div>
        <template>
          <div>
            <a-row>
              <a-col :lg="{span: 6}" :xxl="{span: 4}">
                <el-card :style="{height: screenHeight+'px'}">
                  <!--<el-card >-->
                  <div style="height: 0.8rem;">
                    <span style="font-weight: 600;">类别:</span>
                    <span style="margin-left: 1rem">
                      <a-radio-group v-model="changeTreeType" :style="{ marginBottom: '8px' }">
                          <a-radio-button value="mp">
                              监测点
                          </a-radio-button>
                          <a-radio-button value="mm">
                              仪表类型
                          </a-radio-button>
                      </a-radio-group>
                    </span>
                  </div>
                  <br/>
                  <div style="margin-bottom: 12px;"></div>
                  <template>
                    <div v-if="tree_change">
                      <div v-if="changeTreeType === 'mp'">
                        <a-input-search style="margin-bottom: 8px" placeholder="搜索监测点"
                                        @change="onChange">
                        </a-input-search>
                        <a-tree

                            :replaceFields="tree_replaceFields"
                            :style="{height: screenHeight-60+'px',overflow:'auto'}"
                            class="scroll_bar"
                            @expand="onExpand"
                            @select="onSelect"
                            show-icon
                            :expanded-keys="expandedKeys"
                            :auto-expand-parent="autoExpandParent"
                            :tree-data="measurePointData"
                        >
                          <a-icon slot="switcherIcon" type="down"/>
                          <a-icon slot="organization" type="home" theme="twoTone"
                                  two-tone-color="#5AA8FF"/>
                          <a-icon slot="farm" type="home" theme="twoTone"
                                  two-tone-color="#52c41a"/>
                          <a-icon slot="measurePoint" type="api" theme="twoTone"
                                  two-tone-color="#FFA02B"/>

                          <template slot="title" slot-scope="{name}">
                           <span v-if="name.indexOf(searchValue) > -1">
                             {{ name.substr(0, name.indexOf(searchValue)) }}
                             <span style="color: #f50">{{ searchValue }}</span>
                             {{ name.substr(name.indexOf(searchValue) + searchValue.length) }}
                           </span>
                            <span v-else>{{ name }}</span>
                          </template>
                        </a-tree>
                      </div>
                    </div>
                    <div v-if="tree_change">
                      <div v-if="changeTreeType === 'mm'">
                        <a-tree
                            @select="onMeterModelTreeSelect"
                            :replaceFields="meterModel_tree_replaceFields"
                            :tree-data="meterModelData">
                        </a-tree>
                      </div>
                    </div>
                  </template>
                </el-card>
              </a-col>
              <a-col :lg="{span: 18}" :xxl="{span: 20}">
                <div style="width: 100%">
                  <el-card style="margin: 15px">
                    <div class="display-center-sty">
                      <span style="font-weight: 700;margin-right: 15px">预警参数设置</span>
                      <a-button icon="setting"
                                style="margin-right: 10px;background-color: #ff9511;color:white;;border-color: transparent"
                                @click="configurationWarningRule"
                                v-auth="'warningMonitoring-operation'"
                      >配置预警规则
                      </a-button>
                    </div>
                    <el-table
        header-row-class-name="my-table-head-style"
                        :empty-text="(treeSelectedKeys.length==0&&meterModelTreeSelectedKeys.length==0)?'请选择监测点或者仪表类型':'暂无数据'"
                        style="margin-top: 0;margin-bottom: 0;margin-top: 14px;"
                        v-loading="spinning"
                        :data="warningRuleData"
                        row-key="id"
                    >
                      <el-table-column
                          prop="collectItemName"
                          label="监测参数"
                      >
                      </el-table-column>
                      <el-table-column
                          prop="referenceValue"
                          label="参考值"
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row.referenceValue }}</span>&nbsp;{{
                            scope.row.unit
                                                                     }}
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="maxValue"
                          label="最大值"
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row.maxValue }}</span>&nbsp;
                          <span>{{ scope.row.unit }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="minValue"
                          label="最小值"
                      >
                        <template slot-scope="scope">
                          <span>{{ scope.row.minValue }}</span>&nbsp;
                          <span>{{ scope.row.unit }}</span>
                        </template>
                      </el-table-column>
                      <el-table-column
                          prop="state"
                          label="状态"
                      >
                        <template slot-scope="scope">
                          <a-badge
                              :status="scope.row.state === 'NORMAL' ? 'success' :'error'"/>
                          {{ scope.row.state | StateFilter }}
                        </template>
                      </el-table-column>
                      <el-table-column
                          width="180"
                          label="操作"
                          align="center"
                          v-if="authVerify.verify('warningMonitoring-edit,warningMonitoring-delete')"
                      >
                        <template slot-scope="scope">
                          <el-button icon="el-icon-edit-outline" size="mini" v-auth="'warningMonitoring-edit'"
                                     @click="editWarningRule(scope.row)">编辑
                          </el-button>
                          <el-button icon="el-icon-delete" size="mini" v-auth="'warningMonitoring-delete'"
                                     @click="deleteWarningRule(scope.row)">
                            删除
                          </el-button>
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-card>
                </div>
              </a-col>
            </a-row>
          </div>
        </template>
      </div>
    </div>
    <template>
      <div>
        <a-drawer
            title="配置预警规则"
            width="430"
            placement="right"
            :closable="false"
            :visible="configurationWarningRuleVisible"
            @close="configurationWarningRuleVisible=false"
        >
          <div>
            <a-form-model :model="warningRuleFormValue" ref="configurationWarningRuleRefForm"
                          :rules="addWarningRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 15 }">
              <a-form-model-item label="监测参数" prop="unit">
                <a-select v-model="warningRuleFormValue.meterCollectItemId"
                          placeholder="请选择监测参数"
                          allowClear>
                  <a-select-option :value="item.id" v-for="item in collectItemRules" :key="item.id"
                                   :disabled="item.settingRule">
                    {{ item.describe }} <span style="color: red;float: right"
                                              v-if="item.settingRule">已经配置</span>
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="参考值" prop="referenceValue">
                <a-input
                    placeholder="请输入参考值"
                    allowClear
                    v-model="warningRuleFormValue.referenceValue"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="最小值" prop="minValue">
                <a-input
                    placeholder="请输入最小值"
                    allowClear
                    v-model="warningRuleFormValue.minValue"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="最大值" prop="maxValue">
                <a-input
                    placeholder="请输入最大值"
                    allowClear
                    v-model="warningRuleFormValue.maxValue"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="状态" prop="unit">
                <a-select v-model="warningRuleFormValue.state"
                          allowClear
                          placeholder="请选择状态">
                  <a-select-option value="NORMAL">
                    生效
                  </a-select-option>
                  <a-select-option value="DISABLED">
                    失效
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-form-model>
          </div>
          <div :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="configurationWarningRuleVisible=false">
              关闭
            </a-button>
            <a-button type="primary" @click="toAddWarningRule">
              确定
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
    <template>
      <div>
        <a-drawer
            title="编辑预警规则"
            width="430"
            placement="right"
            :closable="false"
            :visible="editWarningRuleVisible"
            @close="editWarningRuleVisible=false"
        >
          <div>
            <a-form-model :model="warningRuleFormValue" ref="addWarningRuleRefForm"
                          :rules="addWarningRules" :label-col="{ span: 6 }"
                          :wrapper-col="{ span: 15 }">
              <a-form-model-item label="监测参数" prop="unit">
                <a-select v-model="warningRuleFormValue.meterCollectItemId" disabled>
                  <a-select-option :value="item.id" v-for="item in collectItemRules" :key="item.id"
                  >
                    {{ item.collectItem }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="参考值" prop="referenceValue">
                <a-input
                    placeholder="请输入参考值"
                    allowClear
                    v-model="warningRuleFormValue.referenceValue"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="最小值" prop="minValue">
                <a-input
                    placeholder="请输入最小值"
                    allowClear
                    v-model="warningRuleFormValue.minValue"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="最大值" prop="maxValue">
                <a-input
                    placeholder="请输入最大值"
                    allowClear
                    v-model="warningRuleFormValue.maxValue"
                    type="number"
                />
              </a-form-model-item>
              <a-form-model-item label="状态" prop="unit">
                <a-select v-model="warningRuleFormValue.state"
                          allowClear placeholder="请选择状态">
                  <a-select-option value="NORMAL">
                    生效
                  </a-select-option>
                  <a-select-option value="DISABLED">
                    失效
                  </a-select-option>
                </a-select>
              </a-form-model-item>

            </a-form-model>
          </div>
          <div :style="{
              position: 'absolute',
              right: 0,
              bottom: 0,
              width: '100%',
              borderTop: '1px solid #e9e9e9',
              padding: '10px 16px',
              background: '#fff',
              textAlign: 'right',
              zIndex: 1,
            }"
          >
            <a-button :style="{ marginRight: '8px' }" @click="editWarningRuleVisible=false">
              关闭
            </a-button>
            <a-button type="primary" @click="toEditWarningRule">
              确定
            </a-button>
          </div>
        </a-drawer>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      meterModel_tree_replaceFields: {children: 'children', title: 'meterName', key: 'id'},
      tree_change: true,
      collectItemRules: [],
      warningRuleFormValue: {
        id: '',
        measurePointId: '',
        meterModelId: '',
        meterCollectItemId: undefined,
        maxValue: '',
        minValue: '',
        referenceValue: '',
        state: '',
      },
      addWarningRules: {
        state: [
          {required: true, message: '请选择状态!', trigger: 'blur'},
        ],
      },
      warningRuleData: [],
      configurationWarningRuleVisible: false,
      editWarningRuleVisible: false,
      changeTreeType: 'mp',
      spinning: true,
      screenWidth: document.documentElement.clientWidth,//屏幕宽度
      screenHeight: document.documentElement.clientHeight - 50,//屏幕高度
      titleData: [], //标题所有title的数据
      expandedKeys: [], //树key
      searchValue: '',  //树搜索
      autoExpandParent: true,  //树
      searchText: '', //搜索
      searchInput: null,  //搜索
      treeSelectedKeys: [],
      meterModelTreeSelectedKeys: [],
      measurePointData: [],
      meterModelData: [],
      tree_replaceFields: {children: 'children', title: 'name', key: 'code'},


      WarningHistoryData: [],
      query: {
        time: [],
        measurePointId: '',
      },
    }
  },
  watch: {
    'changeTreeType': function (val) {
      console.log(val);
      this.treeSelectedKeys = [];
      this.meterModelTreeSelectedKeys = [];
      this.expandedKeys = [];
      this.tree_change = false;
      // 在组件移除后，重新渲染组件
      // this.$nextTick可实现在DOM 状态更新后，执行传入的方法。
      this.$nextTick(() => {
        this.tree_change = true;
      });
    },
    'screenWidth': function (val) { //监听屏幕宽度变化
      console.log(val);
    },
    'screenHeight': function (val) { //监听屏幕高度变化
      console.log(val);
    },
    'treeSelectedKeys': function (val) {
      this.warningRuleData = [];
      this.collectItemRules = [];
      this.getWarningRule();
      this.getCanSettingRuleCollectItem();
    },
    'meterModelTreeSelectedKeys': function (val) {
      this.warningRuleData = [];
      this.collectItemRules = [];
      this.getWarningRule();
      this.getCanSettingRuleCollectItem();
    },
  },

  created() {
    this.getMeasurePointData();
    this.getAllMeterModelByOrganizationId();
    this.getWarningRule();
  },

  mounted() {
    window.onresize = () => { // 定义窗口大小变更通知事件
      this.screenWidth = document.documentElement.clientWidth; //窗口宽度
      this.screenHeight = document.documentElement.clientHeight - 50; //窗口高度
    };
  },

  filters: {
    StateFilter: function (value) {
      if (!value) return "";
      if (value === 'NORMAL') {
        return "生效";
      } else if (value === 'DISABLED') {
        return "失效";
      }
    },
  },
  computed: {
    pageHeight: function () {
      let height = document.documentElement.clientHeight;
      return height - 50;
    }
  },
  methods: {
    editWarningRule(val) {
      this.cleanWarningRule();
      console.log(val);
      this.warningRuleFormValue = {
        id: val.id,
        meterCollectItemId: val.meterCollectItemId,
        maxValue: val.maxValue,
        minValue: val.minValue,
        referenceValue: val.referenceValue,
        state: val.state,
      };
      if (this.treeSelectedKeys.length === 0 && this.meterModelTreeSelectedKeys.length === 0) {
        this.$message.warning("请选择要配置预警规则的监测点");
        return;
      }
      this.editWarningRuleVisible = true;
    },
    deleteWarningRule(key) {
      let self = this;
      this.$confirm({
        title: '确定删除吗?',
        content: '',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
            if(self.changeTreeType === 'mm'){
                self.http.warningData.deleteMeterWarningRule({
                    id: key.id
                }).then(response => {
                    if (response.data.status == 200) {
                        this.$message.success(response.data.msg);
                        self.getWarningRule();
                    } else {
                        this.$message.error(response.data.msg);
                    }
                })
            }else {
                self.http.warningData.deleteWarningRule({
                    id: key.id
                }).then(response => {
                    if (response.data.status == 200) {
                        this.$message.success(response.data.msg);
                        self.getWarningRule();
                    } else {
                        this.$message.error(response.data.msg);
                    }
                })
            }
        },
        onCancel() {
        },
      });
    },
    configurationWarningRule() {
      this.cleanWarningRule();
      if (this.treeSelectedKeys.length === 0 && this.meterModelTreeSelectedKeys.length === 0) {
        this.$message.warning("请选择要配置预警规则的监测点");
        return;
      }
      this.warningRuleFormValue.state = 'NORMAL';
      this.configurationWarningRuleVisible = true;
    },
    cleanWarningRule() {
      this.warningRuleFormValue = {
        id: '',
        meterCollectItemId: undefined,
        maxValue: '',
        minValue: '',
        referenceValue: '',
        state: '',
        meterModelId: '',
      };
    },
    toAddWarningRule() {
      if (this.warningRuleFormValue.maxValue === '' && this.warningRuleFormValue.minValue === '') {
        this.$message.warning("请至少输入一个最大值或最小值");
        return;
      }
      if (this.changeTreeType === 'mp') {
        this.warningRuleFormValue.measurePointId = this.treeSelectedKeys[0];
        this.http.warningData.createWarningRule(this.warningRuleFormValue)
            .then(response => {
                if (response.data.status == 200) {
                    this.$message.success(response.data.msg);
                    this.configurationWarningRuleVisible = false;
                } else {
                    this.$message.warning(response.data.msg);
                }
                this.getWarningRule();
            })
      } else {
        this.warningRuleFormValue.meterModelId = this.meterModelTreeSelectedKeys[0];
          this.http.warningData.createMeterWarningRule(this.warningRuleFormValue)
              .then(response => {
                  if (response.data.status == 200) {
                      this.$message.success(response.data.msg);
                      this.configurationWarningRuleVisible = false;
                  } else {
                      this.$message.warning(response.data.msg);
                  }
                  this.getWarningRule();
              })
      }
    },
    toEditWarningRule() {
      if (this.warningRuleFormValue.maxValue === '' && this.warningRuleFormValue.minValue === '') {
        this.$message.warning("请至少输入一个最大值或最小值");
        return;
      }
      if (this.changeTreeType === 'mm') {
        this.http.warningData.updateMeterWarningRule(this.warningRuleFormValue)
            .then(response => {
                if (response.data.status == 200) {
                    this.$message.success(response.data.msg);
                    this.editWarningRuleVisible = false;
                } else {
                    this.$message.warning(response.data.msg);
                }
                this.getWarningRule();
            })
      } else{
          this.warningRuleFormValue.measurePointId = this.treeSelectedKeys[0];
          this.http.warningData.updateWarningRule(this.warningRuleFormValue)
              .then(response => {
                  if (response.data.status == 200) {
                      this.$message.success(response.data.msg);
                      this.editWarningRuleVisible = false;
                  } else {
                      this.$message.warning(response.data.msg);
                  }
                  this.getWarningRule();
              })
      }
    },
    getWarningRule() {
      this.http.warningData.getWarningRule( {
          measurePointId: this.treeSelectedKeys[0],
          meterModelId: this.meterModelTreeSelectedKeys[0],
      }).then(response => {
        if (response.data.status === 200) {
          this.warningRuleData = response.data.data;
        }
        this.spinning = false;
      });
      this.getCanSettingRuleCollectItem();
    },
    getCanSettingRuleCollectItem() {
      this.http.warningData.getCollectItemRules( {
          measurePointId: this.treeSelectedKeys[0],
          meterModelId: this.meterModelTreeSelectedKeys[0],
      }).then(response => {
        if (response.data.status === 200) {
          this.collectItemRules = response.data.data;
        } else {
          this.$message.warning('数据获取失败');
        }
      });
    },
    getMeasurePointData() {
      this.http.monitor.getOrganizationMeasurePointTree({}).then(response => {
        if (response.data.status === 200) {
          let list = response.data.data
          list.forEach((val) => {
            val.selectable = false;
            if (val.children) {
              val.children.forEach((vall) => {
                vall.selectable = false;
              });
            }
          });
          this.measurePointData = list;
          this.getTitleData(list);
        } else {
          this.$message.warning('数据获取失败');
        }
      });
    },
    getAllMeterModelByOrganizationId() {
      this.http.device.getAllMeterModelByOrganizationId().then((res) => {
        if (res.data.status === 200) {
          this.meterModelData = res.data.data;
        } else {
          this.$message.warning("数据获取失败");
        }
      });
    },

    //树依赖方法
    //树   展开节点的时候触发
    onExpand(expandedKeys) {
      console.log(expandedKeys);
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    //当点击选择的时候
    onSelect(selectedKeys) {
      console.log(selectedKeys);
      this.treeSelectedKeys = selectedKeys;
      if (this.treeSelectedKeys.length > 0) {
        this.spinning = true;
      }

    },
    onMeterModelTreeSelect(selectedKeys) {
      console.log(selectedKeys);
      this.meterModelTreeSelectedKeys = selectedKeys;
      if (this.meterModelTreeSelectedKeys.length > 0) {
        this.spinning = true;
      }
    },
    onChange(e) {
      console.log("搜索中");
      const value = e.target.value;
      if (value === "") {
        this.expandedKeys = [];
        this.searchValue = "";
      } else {
        const expandedKeys = this.titleData
            .map(item => {
              if (item.title.indexOf(value) > -1) {
                return this.getParentKey(item.title, this.measurePointData);
              }
              return null;
            })
            .filter((item, i, self) => item && self.indexOf(item) === i);
        Object.assign(this, {
          expandedKeys,
          searchValue: value,
          autoExpandParent: true,
        });
      }
    },
    //树 获取父id 去展开
    getParentKey(title, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          //注意
          if (node.children.some(item => item.name === title)) {
            //注意
            parentKey = node.code;
          } else if (this.getParentKey(title, node.children)) {
            parentKey = this.getParentKey(title, node.children);
          }
        }
      }
      return parentKey;
    },
    //获取树的所有节点值
    getTitleData(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        //注意
        const title = node.name;
        this.titleData.push({
          title: title
        });
        if (node.children) {
          this.getTitleData(node.children);
        }
      }
    },
  },
}
</script>

<style scoped>
/*滚动条整体样式*/
.scroll_bar::-webkit-scrollbar {
  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
}

.scroll_bar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: #cecece;
}

.scroll_bar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #ededed;
}
</style>
